.tellMeMoreButton {
    mim-width: 160px;
    min-height: 42px;
    margin: 0;
    padding: .5rem 1rem;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    transition: opacity .8s ease 0s;

    background-color: #f04238;
    border: 1px solid #f04238;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px
}

.tellMeMoreButton:hover {
    color: #f2f2f2;
    background-color: #f04238;
    box-shadow: 0 1px 6px rgba(0,0,0,.1), 0 2px 24px rgba(0,0,0,.2);
    transition: box-shadow .1s ease-in-out;
}