body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0f102b;
  overflow: hidden;

  /* Disable user interaction */
  /*cursor: none !important;*/
  user-select: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
